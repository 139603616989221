<template>

  <div>
    <b-row
      class="content-header"
    >

      <!-- Content Left -->
      <b-col
        class="content-header-left mb-2"
        cols="12"
        md="12"
      >
        <b-row class="breadcrumbs-top">
          <b-col cols="12">
            <h1 class="custom-header-title float-left pr-1 mb-0">
              Create new Article
            </h1>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
    <b-alert
      variant="primary"
      show
    >
      <div class="alert-body">
        <span><strong>For Nirvana SG</strong> This is the control panel where administrators can edit the content for the customer-facing mobile app.</span>
      </div>
    </b-alert>
    <!-- Table Container Card -->
    <b-form @submit.prevent="submitForm">
      <validation-observer
        ref="articleCreateForm"
        #default="{invalid}"
      >
        <b-card
          header-tag="header"
        >
          <b-row>
            <b-col
              md="12"
              class="my-2"
            >
              <b-form-group
                label="Icons*"
                label-cols-md="2"
                label-for="image"
              >
                <div class="d-flex justify-content-start">
                  <div
                    class="image__block"
                  >
                    <b-img
                      class="mb-1 mb-sm-0 user__avatar img-preview-block-100"
                      :src="fileSrc ? fileSrc : defaultImage"
                    />
                  </div>

                  <div
                    class="upload__buttons my-auto mx-2"
                  >
                    <p>{{ imageName }}</p>
                    <b-form-file
                      ref="fileInput"
                      v-model="image"
                      type="file"
                      accept="image/jpeg, image/jpg, image/png"
                      style="display: none"
                      @input="setImage"
                    />
                    <b-button
                      variant="primary"
                      class="mr-50"
                      @click="$refs.fileInput.$el.childNodes[0].click()"
                    >
                      Upload
                    </b-button>
                    <b-button
                      v-if="fileSrc"
                      variant="outline-primary"
                      class="mr-50"
                      @click="removeFileSrc()"
                    >
                      Remove
                    </b-button>
                  </div>
                </div>
              </b-form-group>
            </b-col>
            <b-col cols="md-12">
              <validation-provider
                #default="{ errors }"
                name="Category"
                vid="category"
                rules="required"
              >
                <b-form-group
                  label="Category*"
                  label-for="h-article-category"
                  label-cols-md="2"
                  :state="(errors.length > 0 || categoryValidation) ? false : null"
                >
                  <v-select
                    id="h-article-category"
                    v-model="category"
                    label="name"
                    placeholder="Choose from list"
                    :options="categoryOptions"
                    :reduce="name => name._id"
                    :clearable="true"
                    @input="categoryValidation == true ? categoryValidation = false : null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                  <small
                    v-if="categoryValidation"
                    class="text-danger"
                  >
                    {{ categoryError }}
                  </small>
                </b-form-group>
              </validation-provider>
            </b-col>

            <b-col cols="md-12">
              <b-form-group
                label="Title*"
                label-for="h-article-title"
                label-cols-md="2"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Title"
                  vid="title"
                  rules="required"
                >
                  <b-form-input
                    id="h-article-title"
                    v-model="title"
                    placeholder=""
                    :state="(errors.length > 0 || titleValidation) ? false : null"
                    name="title"
                    @input="titleValidation == true ? titleValidation = false : null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                  <small
                    v-if="titleValidation"
                    class="text-danger"
                  >
                    {{ titleError }}
                  </small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col cols="md-12">
              <b-form-group
                label="Content*"
                label-for="h-article-title"
                label-cols-md="2"
              >
                <quill-editor
                  ref="quillTextEditor"
                  class="editor d-block"
                  :value="content"
                  :options="editorOption"
                  @change="onEditorChange"
                />
                <small
                  v-if="contentValidation"
                  class="text-danger"
                >
                  {{ contentError }}
                </small>
              </b-form-group>
            </b-col>

            <b-col cols="md-12">
              <validation-provider
                #default="{ errors }"
                name="Status"
                vid="status"
                rules="required"
              >
                <b-form-group
                  label="Status*"
                  label-for="h-event-status"
                  label-cols-md="2"
                  :state="(errors.length > 0 || statusValidation) ? false : null"
                >
                  <v-select
                    id="h-event-status"
                    v-model="status"
                    label="title"
                    :options="statusOptions"
                    :reduce="title => title.code"
                    :clearable="false"
                    @input="statusValidation == true ? statusValidation = false : null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                  <small
                    v-if="statusValidation"
                    class="text-danger"
                  >
                    {{ statusError }}
                  </small>
                </b-form-group>
              </validation-provider>
            </b-col>
          </b-row>
        </b-card>

        <b-nav class="wrap-border save-nav">
          <li
            class="nav-item ml-auto"
          >
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              type="button"
              variant="flat-primary"
              :to="{ name: 'app-articles-index', params: { type: 'all-article' } }"
            >
              Cancel
            </b-button>
          </li>
          <li
            class="nav-item mr-1"
          >
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              type="submit"
              variant="success"
              :disabled="content != '' ? invalid : true"
            >
              <feather-icon
                icon="PlusIcon"
                class="mr-50"
              />
              <span class="align-middle">Create new Article</span>
            </b-button>
          </li>
        </b-nav>
      </validation-observer>
    </b-form>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BCard, BRow, BCol, BFormGroup, BFormInput, BForm, BButton, BNav, BAlert, BImg, BFormFile,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import { required } from '@validations'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { quillEditor } from 'vue-quill-editor'
import '@core/scss/vue/libs/vue-flatpicker.scss'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BButton,
    BNav,
    BAlert,
    BImg,
    BFormFile,

    quillEditor,
    vSelect,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      // eslint-disable-next-line global-require
      defaultImage: require('@/assets/images/placeholder/placeholder_image.png'),
      image: null,
      fileSrc: '',
      category: '',
      title: '',
      content: '',
      imageName: '',
      status: 'active',
      categoryError: 'Valid category is required',
      titleError: 'Valid title is required',
      contentError: 'Valid content is required',
      categoryValidation: false,
      titleValidation: false,
      contentValidation: false,
      statusValidation: false,
      statusError: 'Valid status is required',
      statusOptions: [
        { title: 'Active', code: 'active' },
        { title: 'Inactive', code: 'inactive' },
      ],

      categoryOptions: [],

      // validation rules
      required,
    }
  },
  beforeMount() {
    this.$http.get('customer/articles/respond-with/category-options')
      .then(response => {
        this.categoryOptions = response.data.categoryOptions ?? []
        if (this.$route.query.category) {
          this.category = this.$route.query.category
        }
      })
      .catch(error => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: error.data.message ?? process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  },
  methods: {
    setImage() {
      if (this.image) {
        if (this.image && this.image.size <= 5242880) {
          const reader = new FileReader()
          this.imageName = this.image.name
          reader.readAsDataURL(this.image)
          reader.onload = () => {
            this.fileSrc = reader.result
          }
        } else {
          this.fileSrc = null
          this.imageName = ''
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'File size not accepted more than 5MB',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        }
      }
    },
    removeFileSrc() {
      this.fileSrc = null
      this.image = null
      this.imageName = ''
    },
    onEditorChange(value) {
      this.content = value.html
    },
    submitForm() {
      this.$refs.articleCreateForm.validate().then(success => {
        if (success) {
          const formData = new FormData()

          formData.append('category', this.category)
          formData.append('image', this.fileSrc)
          formData.append('iconName', this.imageName)
          formData.append('title', this.title)
          formData.append('content', this.content)
          formData.append('status', this.status)

          this.$http.post('customer/articles/store', formData)
            .then(response => {
              this.$swal({
                title: 'Article Created',
                html: `New article <strong>${this.title}</strong> has been created successfully.`,
                // eslint-disable-next-line global-require
                imageUrl: require('@/assets/images/icons/save.png'),
                imageWidth: 80,
                imageHeight: 80,
                imageAlt: 'Custom Icon',
                showCancelButton: true,
                reverseButtons: true,
                cancelButtonText: 'Return to Articles',
                allowOutsideClick: false,
                confirmButtonText: 'View Entry',
                customClass: {
                  confirmButton: 'btn btn-primary ml-1',
                  cancelButton: 'btn btn-outline-primary',
                },
                buttonsStyling: false,
              })
                .then(result => {
                  if (result.value) {
                    this.$router.push({ name: 'app-articles-show', params: { id: response.data.data._id } })
                  } else {
                    this.$router.push({ name: 'app-articles-index', params: { type: 'all-article' } })
                  }
                })
            })
            .catch(error => {
              if (error.data.errors) {
                error.data.errors.forEach(validationError => {
                  if (validationError.param === 'category') {
                    this.categoryError = validationError.msg
                    this.categoryValidation = true
                  } else if (validationError.param === 'title') {
                    this.titleError = validationError.msg
                    this.titleValidation = true
                  } else if (validationError.param === 'content') {
                    this.contentError = validationError.msg
                    this.contentValidation = true
                  } else if (validationError.param === 'status') {
                    this.statusError = validationError.msg
                    this.statusValidation = true
                  }
                })

                const items = document.getElementsByClassName('text-danger')
                window.scrollTo({
                  top: items[0].offsetTop,
                  behavior: 'smooth',
                })
              } else {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: error.data.message ?? process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
                    icon: 'AlertTriangleIcon',
                    variant: 'danger',
                  },
                })
              }
            })
        }
      })
    },
  },
  setup() {
    const editorOption = {
      modules: {
        // toolbar: '#quill-editor',
        toolbar: [
          ['bold', 'italic', 'underline'],
          // ['blockquote', 'code-block'],
          [{ header: 1 }, { header: 2 }],
          [{ list: 'ordered' }, { list: 'bullet' }],
          // [{ script: 'sub' }, { script: 'super' }],
          // [{ indent: '-1' }, { indent: '+1' }],
          // [{ direction: 'rtl' }],
          // [{ size: ['small', false, 'large', 'huge'] }],
          // [{ header: [1, 2, 3, 4, 5, 6, false] }],
          // [{ font: [] }],
          // [{ color: [] }, { background: [] }],
          // [{ align: [] }],
          // ['clean'],
          // ['link', 'image', 'video'],
          ['link'],
        ],
      },
      theme: 'snow',
      placeholder: '',
    }

    return {
      editorOption,
    }
  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/quill.scss';
</style>

<style lang="scss" scoped>
 .editor {
      min-height: 450px;
      overflow: hidden;
      border: 1px solid #D2DCEA;
      border-radius: 5px;
  }
</style>
